import step1 from "img/distractor-tutorial/step1.gif";
import step2 from "img/distractor-tutorial/step2.gif";

export default [
  {
    title: "Paste an Article",
    image: step1,
  },
  {
    title: "Review Results and Export",
    image: step2,
  },
];
